
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    :append-to-body="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'" :inline="true">
        <el-form-item label="课程" prop="courseName">
          <el-input v-model="formModel.courseName" disabled style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="班级序号" prop="sortNo">
          <el-input v-model="formModel.sortNo" placeholder="请输入序号" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="班级名称" prop="className">
          <el-input v-model="formModel.className" placeholder="请输入班级名称" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="主教" prop="coach">
          <el-input v-model="formModel.coach" placeholder="请输入主教" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="助教" prop="assistant">
          <el-input v-model="formModel.assistant" placeholder="请输入助教" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="场所" prop="place">
          <el-input v-model="formModel.place" placeholder="请输入场所" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="formModel.remark" placeholder="请输入备注" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="时间安排" prop="schedule">
          <el-input v-model="formModel.schedule" placeholder="请输入时间安排" style="width:200px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import interestClassApi from "@/api/interest/interestClass";

export default {
  props: ["courseId", "businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        sortNo: [{ required: true, message: "序号不能为空", trigger: "blur" }],
        className: [{ required: true, message: "班级名称不能为空", trigger: "blur" }],
        assistant: [{ required: true, message: "助教不能为空", trigger: "blur" }],
        coach: [{ required: true, message: "主教不能为空", trigger: "blur" }],
        place: [{ required: true, message: "场所不能为空", trigger: "blur" }],
        schedule: [{ required: true, message: "时间安排不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {}
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return interestClassApi.add(self.formModel);
            } else {
              return interestClassApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return interestClassApi.create(self.courseId);
      } else {
        return interestClassApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>